* {
  font-family: 'Open Sans', sans-serif;
}

body {
  width: 100vw;
  margin: 0;
}

ul {
  margin-top: 0;
}

.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-image: url('./JSOMBackground.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.body {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.display-linebreak {
  white-space: pre-line;
}

.column {
  float: left;
  height: 80vh;
}

.left {
  width: 35%;
}

.right {
  width: 65%;
}

a {
	text-decoration: none;
	color: black;
}

a:visited {
	color: black;
}

.score {
  width: 500px;
}

.grid {
  z-index: 1;
  position: absolute;
  width: 500px;
}

.grid td {
  padding: 2px;
}

.grid td:hover {
  background-color: #3c5782;
  opacity: .5;
}

.grid .selected {
  background-color: #3c5782;
  opacity: .3;
}

video {
  width: 100%;
  border: 1px solid black;
}

.vid-instruction {
  font-size: 12px;
  font-style: italic;
  color: #3c5782;
  padding: 0;
  margin: 0;
}

.header {
  font-size: 20px;
  color: white;
}

.header-small { display: none; }

@media (max-width: 1000px) {
  .header-small { display: inline-block; }
  .header-large { display: none; }
}

input {
  width: 100%;
}

textarea {
  width: 100%;
}

.home-description {
  z-index: 32;
  background: white;
  padding: 5px;
  padding-left: 20px !important;
  padding-right: 20px;
  border-radius: 20px;
  box-shadow: 3px 4px rgb(0 0 0 / 0.4);
}